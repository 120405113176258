.sidebar-container {
    width: 15% !important;
    background-color: #bda13e !important;
    position: fixed !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 1100 !important;
    display: none !important;
  }

  .sidebar {
    /* padding: 2% !important; */
    margin-top: 20% !important;
  }
  
  .sidebar-logo {
    padding: 2% !important;
    margin: 6% !important;
  }

  .sidebar-link {
    text-decoration: none;
    color: rgb(41, 41, 41);
    padding: 1%;
    display: block;
    border-radius: 8px;
    margin-bottom: 4%;
    margin: auto;
    width: 100% !important;
    transition: background-color 0.3s ease-in-out; 
  }

  .sidebar-link-text {
    text-align: center !important;

  }

  .sidebar-link.active {
    background-color: rgb(41, 41, 41) !important;
    color: white !important;
    border-radius: 8px !important;
    width: 75% !important;
    transition: background-color 0.3s ease-in-out;
  }
  
  .menu-button {
    display: none !important;
    position: fixed;
    top: 10px;
    left: 35px;
    color: #bda13e;
    z-index: 1100;
  }
  
  .mobile-sidebar {
    display: none;
    width: 15% !important;
    background-color: #bda13e !important;
    position: fixed !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 1100 !important;
  }

  .sidebar-logo-mobile {
    
    padding: 2% !important;
    margin: 6% !important;
    margin-bottom: 15% !important;
  }
  
  /* Responsive styling */
  @media (max-width: 600px) {
    .sidebar-container {
      display: none;
    }
    .menu-button {
      display: block !important;
    }
    .mobile-sidebar {
      display: block;
    }
  }
  
