.header {
  background-color: #bda13e !important;
  /* background-color: white !important; */
  color: black !important;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  height: 110px !important;
  justify-content: center !important;
}

.header::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -3px; 
  left: 0;
  width: 100%;
  height: 3px; 
  background: linear-gradient(to bottom, rgba(189, 161, 62, 0.5), rgba(189, 161, 62, 0));
  transition: opacity 0.3s ease;
}

.toolbar {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.logo-container {
  display: flex !important;
  align-items: center !important;
  padding: 1% !important;
}

.logo {
  width: 100px !important;
  padding: 1% !important;
  flex-shrink: 0 !important;
}

.dashboard-text {
  margin-left: 20px !important; 
  color: antiquewhite !important;
  font-family: 'Conthrax' !important;
}

.nav-links {
  display: flex !important;
  align-items: center !important;
  flex-wrap: nowrap !important;
  margin-right: 8px !important;

}

.navlink {
  color: black!important;
    text-decoration: none!important;
    /* margin-left: 3% !important; */
    position: relative!important;
    transition: color 0.3s ease!important;
    /* padding: 10px 10px!important; */
    margin: 10px!important;
    white-space: nowrap;
    font-family: 'Conthrax' , 'sans-serif' !important;
    text-transform: uppercase !important;
    font-size: .8rem !important;
}


#settings-navlink {
  font-size: 1.2rem !important;
  margin: 10px !important;
  position: relative;
}

.settings-nav-icon {
  margin-left: 8px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

#settings-navlink:hover .settings-nav-icon {
  opacity: 1;
}

.navlink.active {
  color: white !important;
}

.navitem-with-dropdown {
  position: relative;
  /* margin-left: 3%; */
  /* padding: 10px 0; */
}

.navitem-with-dropdown .dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgb(41, 41, 41);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 160px;
  border-radius: 8px;
  font-family: 'Conthrax' , 'sans-serif' !important;
  font-size: 1rem !important;
}

.navitem-with-dropdown:hover > .dropdown,
.navitem-with-dropdown:hover > .nested-dropdown {
  display: block;
}

.dropdown-content {
  margin-top: 1% !important;
  display: flex;
  flex-direction: column;
}

.dropdown-navlink {
  font-family: 'Conthrax' !important;
  text-transform: uppercase !important;
  font-size: .8rem !important;
  padding: 12px 16px;
  text-decoration: none;
  color: rgb(185, 159, 36);
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.dropdown-navlink:hover {
  background-color: #bda13e;
  color: white;
  border-radius: 8px;
}

.nested-dropdown {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 1px;
  background-color: rgb(41, 41, 41);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  min-width: 160px;
  border-radius: 8px;
}


/* Responsive styling */
@media (max-width: 600px) {
  .nav-links {
    display: none !important;
  }
}
